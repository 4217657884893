@import '../../../../scss/theme-bootstrap';

$basic-grid-column: 1 2 3 4 5 6 7 8 9 10;

@each $column-small in $basic-grid-column {
  .column-#{$column-small}-small {
    max-width: calc(1 /#{$column-small}* 100% - #{$basic-grid-gutter});
    &.grid-item--no-margin-small {
      max-width: calc(1 /#{$column-small}* 100%);
    }
  }
}

@each $column in $basic-grid-column {
  .column-#{$column} {
    @include breakpoint($medium-up) {
      max-width: calc(1 /#{$column}* 100% - #{$basic-grid-gutter});
    }
    &.grid-item--no-margin-small {
      @include breakpoint($medium-up) {
        max-width: calc(1 /#{$column}* 100% - #{$basic-grid-gutter});
      }
    }
  }
}

.basic-grid-formatter {
  &__grid {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 1;
    margin: -($basic-grid-margin);
    padding: 0;
    &.max-width-768 {
      margin: 0 auto;
      max-width: $tablet;
    }
    &.max-width-1024 {
      margin: 0 auto;
      max-width: $desktop;
    }
    &.max-width-1280 {
      margin: 0 auto;
      max-width: $desktop-wide;
    }
    &.max-width-1366 {
      margin: 0 auto;
      max-width: $desktop-x-wide;
    }
  }
  &__grid-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 1;
    margin: 20px 20px 0;
    @include breakpoint($medium-up) {
      margin: $basic-grid-margin;
    }
    &.grid-item--no-margin-small {
      margin: 0;
      @include breakpoint($medium-up) {
        margin: $basic-grid-margin;
      }
    }
  }
  .max-width {
    @include breakpoint($medium-up) {
      padding: 0 29px;
    }
  }
  &__header {
    &.max-width-768 {
      margin: 0 auto;
      max-width: $tablet;
    }
    &.max-width-1024 {
      margin: 0 auto;
      max-width: $desktop;
    }
    &.max-width-1280 {
      margin: 0 auto;
      max-width: $desktop-wide;
    }
    &.max-width-1366 {
      margin: 0 auto;
      max-width: $desktop-x-wide;
    }
  }
  &__headline {
    text-align: center;
    margin: 10px auto 0;
    @include breakpoint($medium-up) {
      margin: 15px auto 0;
    }
    p {
      margin: 0;
    }
    .title--med,
    .title--med p {
      font-size: 17px;
      @include breakpoint($medium-up) {
        margin: 0 auto;
        font-size: 19px;
      }
    }
  }
}
